import styled from "styled-components";
import { useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Scrollbar } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import MetaTag from "../../layout/MetaTag";

const Container = styled.div`
    .tab-pane { display: none; }
    .tab-pane.active { display: block; }
    .fade { opacity: 0; transition: opacity 0.2s ease-in-out; }
    .fade.show { opacity: 1; }
    .page-title2 {margin-top:110px;}
    .page-title3 {margin-top:110px;}
    .product-tab {
        display:flex; gap:10px; margin-bottom:60px; flex-wrap:wrap;
        .tab-btn {
            display:block; padding:10px 15px 11px; line-height:1;
            border:1px solid #5B5B5B; border-radius:8px; cursor:pointer;
            transition: transform 0.2s ease, background-color 0.2s ease, color 0.2s ease;
            &:hover {background-color:#343434; color:#fff;}
            color: #000;
        }
        .tab-btn.active {background-color:#343434; color:#fff; font-weight:600;}
    }
    .tab-container {display:none;}
    .booth-area {display:flex; gap:20px;}
    .booth-item {flex:1 1 0;}
    .booth-thumb {
        border-radius:18px; border:1px solid rgba(255, 255, 255, 0.20); 
        overflow:hidden; 
        background:url(${process.env.REACT_APP_CDN_URL}/product/kiosk_bg.webp) no-repeat center/cover;
    }
    .booth-thumb img {height:292px; margin:19px auto 10px;}
    .booth-thumb span {
        display:block; width:100%; text-align:center; color:#fff; 
        font-weight:600; background-color:#343434; padding:16px; line-height:1;
    }
    .booth-text {margin-top:20px; font-size:18px; line-height:1.5;}
    .software-point {display:flex; gap:20px;}
    .software-point-box {
            display:flex; justify-content:space-between; gap:24px; 
            flex-direction:column; flex:1 1 0; border-radius:18px; 
            border:1px solid rgba(255, 255, 255, 0.20); overflow:hidden; 
            background:url(${process.env.REACT_APP_CDN_URL}/product/kiosk_bg.webp) no-repeat center/cover;
            align-items:center; padding-top:40px;
            img {max-width:80%; margin:0 auto;}
        }
    .software-point-text {
        display:block; width:100%; color:#fff; font-weight:600; 
        background-color:#343434; padding:16px 20px; text-align:left; 
        line-height:1.5; padding-left:30px; text-indent:-10px;
        span {display:block;}
    }
    .page-title3 {font-size:29px; display:block; margin-bottom:40px; font-weight:600;}
    .software-service {
        .swiper-slide {
            width:auto; text-align:center;
            span {display:block; text-align:center; font-size:18px; margin-top:18px;}
            img {height:435px;}
        }
    }
    .other-text {font-size:18px; padding-top:20px; line-height:1.5;}
    .production-box {max-width:1140px;}
    .production-box + .production-box {margin-top:60px;}
    .production-top {
        display:flex; justify-content:space-between; padding-bottom:16px;
        strong {font-size:24px; font-weight:600;}
    }
    .production-bottom {
        display:flex; justify-content:left; margin-top: 20px;
        strong {font-size:14px; font-weight:500; color:#FF6666;}
    }
    .production-table {
        border-radius:18px; overflow:hidden; background-color:#F9FAFB; flex:auto;
        table {
            width:100%; border-collapse:collapse; font-size:18px; height:100%;
        }
        thead th {background-color:#343434; color:#fff;}
        th, td {border:1px solid #EDEDED; padding:18px 24px; text-align:center;}
        tr td:last-child {text-align:center;}
        tr:last-child th, tr:last-child td {border-bottom:0;}
        tr th:last-child, tr td:last-child {border-right:0;}
        tr th:first-child, tr td:first-child {border-left:0;}
        .production-ex {div {display: flex; justify-content: left;}
        }
    }

    @media screen and (max-width: 767px) {
        .title-tab .search {position: relative;margin-top:30px;}
        .store-list {grid-template-columns: repeat(1, 1fr);}
        .store-item .store-thumb {height:200px;}

        .page-title2 {font-size:24px !important;margin-bottom:30px !important;}
        .store-name {font-size:18px;}
        .store-address {display:block;font-size:15px;margin-top:6px;line-height:1.4;opacity:.7;}
        .root_daum_roughmap .wrap_map {height:250px !important;}
    }
    @media screen and (max-width: 767px) {
        .page-title2 {margin-top:80px;}
        .page-title3 {margin-top:80px;}
        .page-title3 {font-size:20px; line-height:1.3;}
        .product-tab {margin-bottom:30px; gap:8px 10px;}
        .product-tab .tab-btn {padding:8px 14px;}
        .kiosk-wrap .kiosk-table {overflow:auto;}
        .kiosk-wrap .kiosk-table table {font-size:14px; white-space:nowrap;}
        .kiosk-wrap .kiosk-table th, .production-table td {padding:12px 18px;}
        .kiosk-wrap .kiosk-desc {font-size:16px;}
        .kiosk-wrap .kiosk-thumb {width:100%; height:320px;}
        .kiosk-wrap .kiosk-thumb img {height:100%; width:auto; object-fit:cover;}
        .kiosk-wrap2 .kiosk-info {font-size:14px;}
        .kiosk-wrap2 .kiosk-info dt {white-space:nowrap;}
        .kiosk-wrap2 .kiosk-thumb {flex-direction:column; height:auto; padding:20px;}
        .kiosk-wrap2 .kiosk-thumb .thumb-main {padding:0; height:200px;}
        .kiosk-wrap2 .kiosk-thumb .thumb-sub img {height:140px;}
        .kiosk-title {font-size:20px;}
        .booth-area {flex-direction:column;}
        .booth-text {font-size:16px;}
        .booth-area {gap:30px;}
        .software-point {flex-direction:column;}
        .software-point-box {flex:1;}
        .software-service .swiper-slide span {font-size:16px;}
        .software-service .swiper-slide img {height:260px;}
        .other-text {font-size:16px;}
        .production-top strong {font-size:18px;}
        .production-top {flex-direction:column; gap:5px;}
        .production-table {overflow:auto;}
        .production-table table {font-size:14px; white-space:nowrap;}
        .production-table th, .production-table td {padding:12px 18px;}
        .production-ex { div{font-size: 14px !important;}}
    }
`;
const KioskTitle = styled.div`
    font-size:24px; font-weight:600; display:block; margin-bottom:20px;
    & ~ & { margin-top: 60px; }
`;
const KioskWrap = styled.div`
    display:flex; gap:20px; flex-wrap:wrap;
    .kiosk-desc {width:100%; line-height:1.5; font-size:18px;}
    .kiosk-thumb {
        position:relative; width:365px; height:365px; border-radius:18px; 
        border:1px solid rgba(255, 255, 255, 0.20); 
        background:url(${process.env.REACT_APP_CDN_URL}/product/kiosk_bg.webp) no-repeat center/cover; 
        overflow:hidden;
        img { 
            position:absolute; left:50%; top:50%; 
            transform:translate(-50%, -50%);
        }
    }
    .kiosk-table {
        border-radius:18px; overflow:hidden; background-color:#F9FAFB; flex:auto;
        table {
            width:100%; border-collapse:collapse; font-size:18px; height:100%;
        }
        thead th {background-color:#343434; color:#fff;}
        th, td { border:1px solid #EDEDED; padding:18px 10px; }
        tr:last-child th, tr:last-child td { border-bottom:0; }
        tr th:last-child, tr td:last-child { border-right:0; }
        tr th:first-child, tr td:first-child { border-left:0; }
    }
`;
const KioskWrap2 = styled.div`
    .kiosk-info {
        width:100%; line-height:1.5; display:flex; gap:6px; line-height:1.75; 
        margin-top:4px; font-size:18px;
    }
    .kiosk-thumb {
        display:flex; gap:10%; align-items:center; ; width:100%; height:365px; 
        border-radius:18px; border:1px solid rgba(255, 255, 255, 0.20); 
        background:url(${process.env.REACT_APP_CDN_URL}/product/kiosk_bg.webp) no-repeat center/cover; 
        overflow:hidden; margin-bottom:20px; padding-right:40px;
        .thumb-main {
            height:100%; padding-top:40px; padding-left:40px; flex:none;
            img {object-fit:cover; height:100%;}
        }
        .thumb-sub {
            flex:auto; padding:26px 0;
            .swiper-slide {width:auto;}
            img {height:224px;}
        }
    }
`;


export default function Product() {

    const { t, i18n } = useTranslation();
    const location = useLocation();
    const initialTab = location.state?.mainActiveKey || 0;

    const [mainActiveKey, setMainActiveKey] = useState(initialTab);
    const [subActiveKey, setSubActiveKey] = useState(0);

    useEffect(() => {
        setMainActiveKey(initialTab);
    }, [initialTab]);

    const filters = [
        { text: t("productPage.warmFilter") },
        { text: t("productPage.coolFilter") },
        { text: t("productPage.whiteningFilter") },
        { text: t("productPage.callaboFrame") },
    ];

    return (
        <>
            <MetaTag
                title="製品 - Photomag"
                description="Photomagで差別化されたフォトソリューションを通じて最高の収益性と安定的な運営をお約束します。"
                preload={`${process.env.REACT_APP_CDN_URL}/product/product_title1.webp`}
            />
            <Container className="container product">
                <div className="page-header">
                    <div className="page-header-bg">
                        <img
                            srcSet={`
                                ${process.env.REACT_APP_CDN_URL}/product/product_title1-320.webp 320w,
                                ${process.env.REACT_APP_CDN_URL}/product/product_title1-768.webp 768w,
                                ${process.env.REACT_APP_CDN_URL}/product/product_title1-1400.webp 1400w,
                                ${process.env.REACT_APP_CDN_URL}/product/product_title1.webp 1920w
                            `}
                            sizes="(max-width: 320px) 320px, 
                                   (max-width: 768px) 768px, 
                                   (max-width: 1400px) 1400px, 
                                   1920px"
                            src={`${process.env.REACT_APP_CDN_URL}/product/product_title1.webp`}
                            alt="Photomag product logo"
                            width={1531}
                            height={440}
                            loading="eager"
                        />
                    </div>
                    <div className="inner">
                        <h2 className="page-title">{t("common.product")}</h2>
                        <p className="page-text" style={{ wordBreak: "break-word" }}>{t("productPage.title")}</p>
                    </div>
                </div>
                <section>
                    <div className="inner">
                        <div className="product-tab" style={{ marginBottom: "30px" }}>
                            <button className={`tab-btn ${mainActiveKey === 0 ? 'active' : ''}`} onClick={() => setMainActiveKey(0)}>
                                {t("productPage.selfPhoto")}
                            </button>
                            <button className={`tab-btn ${mainActiveKey === 1 ? 'active' : ''}`} onClick={() => setMainActiveKey(1)}>
                                {t("productPage.photoSticker")}
                            </button>
                            {/* <button className={`tab-btn ${mainActiveKey === 2 ? 'active' : ''}`} onClick={() => setMainActiveKey(2)}>
                            신규제품
                        </button> */}
                        </div>
                        <h3 className="page-title2" style={{ marginTop: "0px" }}>{t("productPage.photoKiosk")}</h3>
                        <Tab.Container activeKey={mainActiveKey} className="tab-container">
                            <Tab.Content>
                                <Tab.Pane eventKey={0}>
                                    <KioskTitle className="kiosk-title">{t("productPage.standardKiosk")}</KioskTitle>
                                    <KioskWrap className="kiosk-wrap">
                                        <div className="kiosk-thumb">
                                            <img src={`${process.env.REACT_APP_CDN_URL}/product/kiosk_standard.webp`} alt="Photomag standard kiosk" loading="lazy" />
                                        </div>
                                        <div className="kiosk-table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Device</th>
                                                        <th>Specification</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Monitor</th>
                                                        <td>27inch IR Touch Screen</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Camera</th>
                                                        <td>DSLR Camera Cannon Mirrorless</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Printer</th>
                                                        <td>Dye-sublimation printing</td>
                                                    </tr>
                                                    <tr>
                                                        <th>PC</th>
                                                        <td>AMD Ryzen 5 5500GT / RAM 16G / HDD 256G</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Coin Inserter</th>
                                                        <td>JY-910 / VN-3K</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <p className="kiosk-desc">{t("productPage.standardKioskEx1")}
                                            <br />{t("productPage.standardKioskEx2")}</p>
                                    </KioskWrap>
                                    <KioskTitle className="kiosk-title">{t("productPage.highAngleKiosk")}</KioskTitle>
                                    <KioskWrap className="kiosk-wrap">
                                        <div className="kiosk-thumb">
                                            <img src={`${process.env.REACT_APP_CDN_URL}/product/kiosk_angle.webp`} alt="Photomag high angle kiosk" loading="lazy" />
                                        </div>
                                        <div className="kiosk-table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Device</th>
                                                        <th>Specification</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Monitor</th>
                                                        <td>27inch IR Touch Screen</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Camera</th>
                                                        <td>DSLR Camera Cannon Mirrorless</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Printer</th>
                                                        <td>Dye-sublimation printing</td>
                                                    </tr>
                                                    <tr>
                                                        <th>PC</th>
                                                        <td>AMD Ryzen 5 5500GT / RAM 16G / HDD 256G</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Coin Inserter</th>
                                                        <td>JY-910 / VN-3K</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <p className="kiosk-desc">{t("productPage.highAngleKioskEx1")}
                                            <br />{t("productPage.highAngleKioskEx2")}</p>
                                    </KioskWrap>
                                    <h3 className="page-title2">{t("productPage.photoBooth")}</h3>
                                    <div className="booth-area">
                                        <div className="booth-item">
                                            <div className="booth-thumb">
                                                <img src={`${process.env.REACT_APP_CDN_URL}/product/booth_1.webp`} alt="Photomag booth1" loading="lazy" />
                                                <span>{t("productPage.standardBooth")}</span>
                                            </div>
                                            <p className="booth-text">{t("productPage.standardBoothEx1")}
                                                <br />{t("productPage.standardBoothEx2")}</p>
                                        </div>
                                        <div className="booth-item">
                                            <div className="booth-thumb">
                                                <img src={`${process.env.REACT_APP_CDN_URL}/product/booth_2.webp`} alt="Photomag booth2" loading="lazy" />
                                                <span>{t("productPage.elevatorBooth")}</span>
                                            </div>
                                            <p className="booth-text">{t("productPage.elevatorBoothEx1")}
                                                <br />{t("productPage.elevatorBoothEx2")}</p>
                                        </div>
                                    </div>
                                    <h3 className="page-title2">{t("productPage.photoSoftware")}</h3>
                                    <div className="software-point">
                                        <div className="software-point-box">
                                            <div className="software-point-thumb">
                                                <img src={`${process.env.REACT_APP_CDN_URL}/product/software_point_1.webp`} alt="Photomag softwarePoint1" loading="lazy" />
                                            </div>
                                            <p className="software-point-text"><span>- {t("productPage.photoSoftwareEx1")}</span><span>- {t("productPage.photoSoftwareEx4")}</span></p>
                                        </div>
                                        <div className="software-point-box">
                                            <div className="software-point-thumb">
                                                <img src={`${process.env.REACT_APP_CDN_URL}/product/software_point_2.webp`} alt="Photomag softwarePoint2" loading="lazy" />
                                            </div>
                                            <p className="software-point-text"><span>- {t("productPage.photoSoftwareEx5")}</span><span>- {t("productPage.photoSoftwareEx6")}</span></p>
                                        </div>
                                        <div className="software-point-box">
                                            <div className="software-point-thumb">
                                                <img src={`${process.env.REACT_APP_CDN_URL}/product/software_point_3.webp`} alt="Photomag softwarePoint3" loading="lazy" />
                                            </div>
                                            <p className="software-point-text"><span>- {t("productPage.photoSoftwareEx7")}</span><span>- {t("productPage.photoSoftwareEx8")}</span></p>
                                        </div>
                                    </div>
                                    <h4 className="page-title3" style={{ fontSize: "" }}>{t("productPage.photoSoftwareEx2")} {t("productPage.photoSoftwareEx3")}</h4>
                                    <Swiper
                                        slidesPerView={"auto"}
                                        spaceBetween={20}
                                        speed={400}
                                        className="software-service"
                                    >
                                        {filters.map((filter, index) => (
                                            <SwiperSlide key={index}>
                                                <img 
                                                    src={`${process.env.REACT_APP_CDN_URL}/product/software_service_${index + 1}.webp`}
                                                    alt={`Photomag softwareService${index + 1}`}
                                                    loading="lazy"
                                                />
                                                <span>{filter.text}</span>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <h4 className="page-title3">Others</h4>
                                    <div className="product-tab">
                                        <button className={`tab-btn ${subActiveKey === 0 ? 'active' : ''}`} onClick={() => setSubActiveKey(0)}>
                                            {t("productPage.salesMgt")}
                                        </button>
                                        <button className={`tab-btn ${subActiveKey === 1 ? 'active' : ''}`} onClick={() => setSubActiveKey(1)}>
                                            {t("productPage.dvcMgt")}
                                        </button>
                                        <button className={`tab-btn ${subActiveKey === 2 ? 'active' : ''}`} onClick={() => setSubActiveKey(2)}>
                                            {t("productPage.paperMgt")}
                                        </button>
                                        <button className={`tab-btn ${subActiveKey === 3 ? 'active' : ''}`} onClick={() => setSubActiveKey(3)}>
                                            {t("productPage.manyLng")}
                                        </button>
                                    </div>
                                    <Tab.Container activeKey={subActiveKey}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey={0}>
                                                <img src={`${process.env.REACT_APP_CDN_URL}/product/others_1.webp`} alt="Photomag others1" loading="lazy" />
                                                <p className="other-text">{t("productPage.salesMgtEx")}</p>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={1}>
                                                <img src={`${process.env.REACT_APP_CDN_URL}/product/others_2.webp`} alt="Photomag others2" loading="lazy" />
                                                <p className="other-text">{t("productPage.dvcMgtEx")}</p>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={2}>
                                                <img src={`${process.env.REACT_APP_CDN_URL}/product/others_3.webp`} alt="Photomag others3" loading="lazy" />
                                                <p className="other-text">{t("productPage.paperMgtEx")}</p>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={3}>
                                                <img src={`${process.env.REACT_APP_CDN_URL}/product/others_4.webp`} alt="Photomag others4" loading="lazy" />
                                                <p className="other-text">{t("productPage.manyLngEx")}</p>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey={1}>
                                    <KioskTitle className="kiosk-title">{t("productPage.colorStKiosk")}</KioskTitle>
                                    <KioskWrap2 className="kiosk-wrap2">
                                        <div className="kiosk-thumb">
                                            <div className="thumb-main">
                                                <img src={`${process.env.REACT_APP_CDN_URL}/product/kiosk_sticker_1.webp`} alt="Photomag kioskSticker1" loading="lazy" />
                                            </div>
                                            <Swiper
                                                slidesPerView={"auto"}
                                                spaceBetween={40}
                                                speed={400}
                                                scrollbar={{ hide: false, draggable: true }}
                                                modules={[Scrollbar]}
                                                className="thumb-sub"
                                            >
                                                {Array.from({ length: 4 }, (_, index) => (
                                                    <SwiperSlide key={index}>
                                                        <img 
                                                            src={`${process.env.REACT_APP_CDN_URL}/product/kiosk_sticker_1_${index + 1}.webp`}
                                                            alt={`Photomag kioskSticker1_${index + 1}`}
                                                            loading="lazy"
                                                        />
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                        <dl className="kiosk-info">
                                            <dt>{t("productPage.colorSize")} :</dt>
                                            <dd>{t("productPage.colorStKioskSize")}</dd>
                                        </dl>
                                        <dl className="kiosk-info">
                                            <dt>{t("productPage.characteristic")} :</dt>
                                            <dd>
                                                <ul>
                                                    <li>(1) {t("productPage.colorNmStKioskEx1")}</li>
                                                    <li>(2) {t("productPage.colorNmStKioskEx2")}</li>
                                                    <li>(3) {t("productPage.colorNmStKioskEx3")}</li>
                                                    <li>(4) {t("productPage.colorNmStKioskEx4")}</li>
                                                    <li>(5) {t("productPage.colorNmStKioskEx5")}</li>
                                                </ul>
                                            </dd>
                                        </dl>
                                    </KioskWrap2>
                                    <KioskTitle className="kiosk-title">{t("productPage.polapolaSt")}</KioskTitle>
                                    <KioskWrap2 className="kiosk-wrap2">
                                        <div className="kiosk-thumb">
                                            <div className="thumb-main">
                                                <img src={`${process.env.REACT_APP_CDN_URL}/product/kiosk_sticker_2.webp`} alt="Photomag kioskSticker2" loading="lazy" />
                                            </div>
                                            <Swiper
                                                slidesPerView={"auto"}
                                                spaceBetween={40}
                                                speed={400}
                                                scrollbar={{ hide: false, draggable: true }}
                                                modules={[Scrollbar]}
                                                className="thumb-sub"
                                            >
                                                {Array.from({ length: 4 }, (_, index) => (
                                                    <SwiperSlide key={index}>
                                                        <img 
                                                            src={`${process.env.REACT_APP_CDN_URL}/product/kiosk_sticker_2_${index + 1}.webp`}
                                                            alt={`Photomag kioskSticker2_${index + 1}`}
                                                            loading="lazy"
                                                        />
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                        <dl className="kiosk-info">
                                            <dt>{t("productPage.colorSize")} :</dt>
                                            <dd>{t("productPage.colorStKioskSize")}</dd>
                                        </dl>
                                        <dl className="kiosk-info">
                                            <dt>{t("productPage.characteristic")} :</dt>
                                            <dd>
                                                <ul>
                                                    <li>(1) {t("productPage.polapolaEx1")}</li>
                                                    <li>(2) {t("productPage.polapolaEx2")}</li>
                                                    <li>(3) {t("productPage.polapolaEx3")} - {t("productPage.polapolaEx3-1")}</li>
                                                    <li>(4) {t("productPage.polapolaEx4")}</li>
                                                </ul>
                                            </dd>
                                        </dl>
                                    </KioskWrap2>
                                    <KioskTitle className="kiosk-title">{t("productPage.poluv")}</KioskTitle>
                                    <KioskWrap2 className="kiosk-wrap2">
                                        <div className="kiosk-thumb">
                                            <div className="thumb-main"><img src={`${process.env.REACT_APP_CDN_URL}/product/kiosk_sticker_3.webp`} alt="Photomag kioskSticker3" loading="lazy" /></div>
                                            <Swiper
                                                slidesPerView={"auto"}
                                                spaceBetween={40}
                                                speed={400}
                                                scrollbar={{ hide: false, draggable: true }}
                                                modules={[Scrollbar]}
                                                className="thumb-sub"
                                            >
                                                {Array.from({ length: 4 }, (_, index) => (
                                                    <SwiperSlide key={index}>
                                                        <img 
                                                            src={`${process.env.REACT_APP_CDN_URL}/product/kiosk_sticker_3_${index + 1}.webp`}
                                                            alt={`Photomag kioskSticker3_${index + 1}`}
                                                            loading="lazy"
                                                        />
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                        <dl className="kiosk-info">
                                            <dt>{t("productPage.colorSize")} :</dt>
                                            <dd>{t("productPage.colorStKioskSize")}</dd>
                                        </dl>
                                        <dl className="kiosk-info">
                                            <dt>{t("productPage.characteristic")} :</dt>
                                            <dd>
                                                <ul>
                                                    <li>(1) {t("productPage.poluvEx1")}</li>
                                                    <li>(2) {t("productPage.poluvEx2")}</li>
                                                    <li>(3) {t("productPage.poluvEx3")}</li>
                                                    <li>(4) {t("productPage.poluvEx4")}</li>
                                                    <li>(5) {t("productPage.poluvEx5")}</li>
                                                    <li>(6) {t("productPage.poluvEx6")}</li>
                                                </ul>
                                            </dd>
                                        </dl>
                                    </KioskWrap2>
                                </Tab.Pane>
                                <Tab.Pane eventKey={2}>

                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </section>
                <section>
                <div className="inner">
                    <h4 className="page-title3">{t("productPage.PrincingPolicy")}</h4>
                    <div className="production-box">
                        <div className="production-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th rowSpan={2}>{t("productPage.policyTitle1")}</th>
                                        <th colSpan={3}>{t("productPage.policyTitle2")}</th> 
                                    </tr>
                                    <tr>
                                        <th>{t("productPage.policyTitle3")}</th>
                                        <th>{t("productPage.policyTitle4")}</th>
                                        <th>{t("productPage.policyTitle5")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{t("productPage.standardA")}</td>
                                        <td>₩15,000,000</td>
                                        <td>₩13,000,000</td>
                                        <td>₩12,000,000</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>{t("productPage.standardB")}</div>
                                            <div>({t("productPage.wideAngle")})</div>
                                        </td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td rowSpan={2}>₩13,000,000</td>
                                    </tr>
                                    <tr>
                                        <td>{t("productPage.highAngle")}</td>
                                        <td>₩18,000,000</td>
                                        <td>₩14,000,000</td>
                                    </tr>
                                    <tr>
                                        <td>{t("productPage.remoteUpdate")}</td>
                                        <td>{t("productPage.support")}</td>
                                        <td>{t("productPage.notSupported")}</td>
                                        <td>{t("productPage.support")}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("productPage.onlineFrameUpdate")}</td>
                                        <td>{t("productPage.support")}</td>
                                        <td>{t("productPage.notSupported")}</td>
                                        <td>{t("productPage.support")}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("productPage.monthlyFee")}</td>
                                        <td className="production-ex" style={{fontSize: i18n.language === "jp" ? "16px" : "inherit"}}>
                                            <div>- {t("productPage.monthlyFee1_1")} 5~8%</div>
                                            <div>- {t("productPage.monthlyFee1_2")}</div>
                                            <div>- {t("productPage.monthlyFee1_3")}</div>
                                        </td>
                                        <td className="production-ex" style={{fontSize: i18n.language === "jp" ? "16px" : "inherit"}}>
                                            <div>- {t("productPage.monthlyFee2_1")}</div>
                                            <div>- {t("productPage.monthlyFee2_2")}</div>
                                        </td>
                                        <td className="production-ex" style={{fontSize: i18n.language === "jp" ? "16px" : "inherit"}}>
                                            <div>- {t("productPage.monthlyFee3_1")}</div>
                                            <div>- {t("productPage.monthlyFee3_2")} = ₩100</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("productPage.hwAs")}</td>
                                        <td colSpan={3}>{t("productPage.hwAs_1")}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="production-bottom">
                            <strong>※ {t("productPage.policyEx")}</strong>
                        </div>
                    </div>
                    {/* <div className="production-box">
                        <div className="production-top">
                            <strong>소프트웨어 제작 판매 가격</strong>
                            <span>(단위:원/부가세포함)</span>
                        </div>
                        <div className="production-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>제품명</th>
                                        <th>판매지역</th>
                                        <th>판매가</th>
                                        <th>비고</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowSpan={2}>Photomag</td>
                                        <td>국내</td>
                                        <td>10,000,000</td>
                                        <td>100대 이상 계약 시 대당 50만원 할인</td>
                                    </tr>
                                    <tr>
                                        <td>해외</td>
                                        <td>10,000,000 (면세)</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> */}
                </div>
            </section>
            </Container>
        </>
    )
}