import { useEffect, useState } from "react"
import styled from "styled-components";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useLocation } from "react-router-dom";

const ScrollButton = styled.button`
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #000000; /* 연한 빨간색 */
    color: white;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    transition: opacity 0.3s;
    opacity: ${(props) => (props.$visible ? 1 : 0)};
    pointer-events: ${(props) => (props.$visible ? "auto" : "none")};
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 200;

    &:hover {
        background-color: #333333;
    }
`;

const ScrollToTop = () => {
    const [visible, setVisible] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const toggleVisible = () => {
            if (window.scrollY > 300) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        };
        window.addEventListener("scroll", toggleVisible);
        return () => window.removeEventListener("scroll", toggleVisible);
    }, []);

    const scrollToTopMove = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    if (location.pathname.startsWith("/board/") || location.pathname.startsWith("/store/")) {
        return null;
    }

    return (
        <ScrollButton onClick={scrollToTopMove} $visible={visible}>
            <ArrowUpwardIcon style={{ fontSize: "20px" }}/>
        </ScrollButton>
    );
}
export default ScrollToTop;